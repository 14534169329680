@import '@angular/material/theming';

// Always include only once per project
@include mat-core();

// Import our custom theme
@import 'sass/app-theme.scss';
@import 'sass/fonts.scss';
@import 'sass/cartus-palette.scss';
@import 'sass/app.scss';
@import 'sass/common-styles.scss';
@import 'sass/dialog.scss';
@import 'sass/snackbar.scss';
@import 'sass/mat-table.scss';
@import 'sass/mat-table-column.scss';
@import 'sass/breadcrum.scss';
@import 'sass/mixins/media-mixins.scss';
@import 'sass/mixins/brand-mixins.scss';
@import 'sass/scss/variables';

@mixin custom-components-theme($theme, $typography, $cartus-palette) {
  @include app($theme, $typography, $cartus-palette);
}

@include angular-material-theme($app-theme);
@include custom-components-theme($app-theme, $typography, $cartus-palette);

// .ccu-theme {
//   // use our theme with angular-material-theme mixin
//   // @include angular-material-theme($app-theme);
//   // include custom components theme mixin once per theme class
//   // @include custom-components-theme($app-theme, $typography, $cartus-palette);
// }

html,
body {
  height: 100%;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  // background: url('./assets/imgs/dash.png') -1px -6px no-repeat;
  // background: url(./assets/imgs/dash.png) no-repeat center center fixed;
  // background-image: url('./assets/imgs/dash.png');
  //background-color: map-get($cartus-palette, 'cartus_layout_container');
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: map-get($cartus-palette, 'cartus_black');
  -moz-osx-font-smoothing: grayscale;
  /* Fix blurry font for Firefox Mac users connected to external displays */
}
input,
select {
  font-size: 100%;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* BRANDING*/

@each $theme, $color-set in $themes {
  @include theme(
    $theme,
    map-get($color-set, primary-color),
    map-get($color-set, secondary-color),
    map-get($color-set, tertiary-color),
    map-get($color-set, footer-color),
    map-get($color-set, nav-link-color),
    map-get($color-set, icon-color)
  );
}

*[fxlayoutgap] > *:last-child {
  margin-right: 0;
  /* Fix for flex last column in a row having a right margin */
}

.btn-primary,
.btn-danger,
.btn-default {
  margin: auto;
  text-align: center;
  color: map-get($cartus-palette, 'cartus_white');
  border: 0;
  display: table;
  height: 46px;
  padding: 11px 27px;
  width: 244px;
  font-size: 20px;
  font-family: 'Roboto-Light', sans-serif;
  font-weight: 300;
  border-radius: 4px;
  margin-bottom: 40px;
  cursor: pointer;
  @include respond-below(md) {
    margin: 8px auto 20px;
    display: block;
    width: 97%;
    padding: 10px;
  }
}
.btn-danger {
  background-color: map-get($cartus-palette, 'cartus_red');
}
.btn-default {
  background-color: map-get($cartus-palette, 'cartus_white');
  color: map-get($cartus-palette, 'cartus_blue');
  border: 1px solid map-get($cartus-palette, 'cartus_blue');
}
.link-danger {
  color: map-get($cartus-palette, 'cartus_red');
  text-decoration: none;
}

.save-draft-btn {
  color: map-get($cartus-palette, 'cartus_footer_link');
  background: white !important;
  border: 1px solid map-get($cartus-palette, 'cartus_footer_link');
}

.save-draft-btn:focus {
  outline: none !important;
}

.mat-snack-bar-handset.cdk-overlay-pane {
  @include respond-below(md) {
    max-width: 100% !important;
    .mat-snack-bar-container {
      min-width: 300px;
    }
  }
}

.mat-snack-bar-container {
  color: map-get($cartus-palette, 'cartus_white') !important;
  background: #2b2e30 !important;
  border-radius: 4px !important;
  max-width: 400px !important;
  height: 48px !important;
  padding: 15px !important;
  position: relative;
  bottom: 3vh;
  left: 13%;
  &.success {
    background-color: #11af2f !important;
  }
  &.feedback {
    background-color: #11af2f !important;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    max-width: 33vw;
    min-width: 344px;
    padding: 14px 16px;
    min-height: 48px;
    float: right;
    position: absolute;
    right: 0px;
    margin: 24px;
    margin-bottom: 6rem !important;
    left: initial;
    min-width: 270px;
    margin-right: 1.5rem;
    @include respond-below(lg) {
      border-radius: 4px;
      box-sizing: border-box;
      display: block;
      max-width: 33vw;
      min-width: 344px;
      padding: 14px 16px;
      min-height: 48px;
      float: right;
      position: absolute;
      right: 0px;
      margin: 24px;
      margin-bottom: 6rem !important;
      left: initial;
      min-width: 270px;
      margin-right: 1.5rem;
    }
    @include respond-below(md) {
      margin-bottom: 0rem !important;
      position: inherit;
    }
  }
  @include respond-below(md) {
    left: 0;
  }
  @include respond-below(sm) {
    padding: 14px 5px !important;
    display: block !important;
    left: 0 !important;
  }
  @include respond-below(xs) {
    padding: 14px 2px !important;
  }
}
@media only screen and (max-device-height: 850px) {
  .mat-snack-bar-container {
    bottom: 0vh;
    left: 13%;
  }
}
.disclaimer-wrapper {
  display: none; //Hidden disclaimer - B2B-3881
  font-size: 15px;
  font-family: 'Roboto-Light', sans-serif;
  font-weight: 300;
  padding: 0px 60px 56px 40px;
  margin-bottom: 48px;

  @include respond-below(md) {
    padding: 0px 10% 56px;
  }
}

.create-referral {
  padding: 2em;
}

.statusBtn {
  .submit {
    background: #58cc6b;
    color: #fff;
  }

  .save {
    background: #f4a814;
    color: #fff;
  }

  .clear {
    background: #e5173e;
    color: #fff;
  }
}

.agent-form-section {
  margin-top: 0;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  padding: 0;
  height: 100%;
  .input-field {
    width: 384px;
  }

  .price-min-max-field {
    display: flex;
  }

  .property-type {
    display: flex;
    flex-wrap: wrap;
  }

  .selling-property-type {
    margin-top: 1em;
  }

  .selling-details-field {
    margin-bottom: 30px;
  }

  .selling-details-text {
    font-size: 15px;
    font-family: 'Roboto-Bold';
    padding-bottom: 40px;
    padding-top: 6px;

    & + .mat-error {
      margin: 10px 0;
    }
  }

  .property-type-field {
    width: 25%;
    padding-bottom: 20px;
    font-family: 'Roboto-Light' !important;
    font-size: 16px !important;
  }

  .ownership-type {
    display: flex;
    flex-wrap: wrap;
  }

  .ownership-type-field {
    width: 25%;
    padding-bottom: 10px;
    font-family: 'Roboto-Light' !important;
    font-size: 16px !important;

    .mat-radio-label {
      margin-right: 3em;
    }
  }

  .additional-comments {
    width: 800px;
    margin-top: 0px;
    margin-bottom: 0px;

    .mat-form-field-flex {
      height: 180px !important;
      padding-right: 20px;
      margin-bottom: 0;
      @media screen and (max-width: 1300px) {
        height: 120px !important;
      }
    }

    textarea {
      height: 95px !important;
      margin-top: -15px !important;
      padding: 10px;
      resize: none;
      overflow: hidden;
    }
    textarea.comment-box{
      height: 150px !important;
      overflow-y: auto;
      @media screen and (max-width: 1300px) {
        height: 95px !important;
      }
    }
  }

  .bying-details-text {
    font-size: 15px;
    margin-top: 0px;
    padding-left: 5px;
    font-family: 'Roboto-Bold';
  }

  .selling-details-address {
    font-size: 15px;
    padding-left: 5px;
    font-family: 'Roboto-Light';
    // margin-bottom: 35px;
    display: inline-flex;
  }

  .selling-details-toggle-btn {
    span {
      font-family: 'Roboto-Light';
      font-size: 15px;
    }
  }

  .selling-details-toggle {
    display: inline-grid;
  }

  .textarea-characters {
    float: right;
    position: relative;
    top: -32px;
    right: -10px;
  }

  .address-input-field {
    width: 803px;

    @include respond-below(md) {
      width: 100%;
    }
  }

  .input-mobile-field {
    width: 122px;
    margin-left: 20px;
    font-weight: 100;
  }

  .input-phone-field {
    width: 244px;
  }

  .state-zip-input-field {
    width: 178px;
    margin-right: 30px;
  }

  .state-zip-input-field:last-child {
    margin-right: 0px;
  }

  .price-input-field {
    width: 178px;
    margin-right: 30px;
  }

  .price-input-field:last-child {
    margin-right: 0px;
  }

  .start-end-date-field {
    width: 178px;
  }

  .start-date {
    margin-right: 9px;
  }

  .end-date {
    margin-left: 9px;
  }

  .min-price {
    margin-right: 13px !important;
  }

  .max-price {
    margin-left: 13px !important;
  }

  input[type='checkbox'i] {
    margin: 3px 10px 3px 4px;
  }

  @include respond-below(xl) {
    margin: 0 10px 50px;
  }

  @include respond-below(md) {
    margin: 0;
    padding: 0;
  }
}

.mat-button-field {
  width: 112px;
  padding: 4px 4px;
  margin-right: 20px !important;
  border: 1px solid #b8bec5;
  font-size: 18px !important;
}

.mat-button-toggle-appearance-standard {
  .mat-button-toggle-label-content {
    line-height: 38px;
  }
}

.mat-select {
  font-family: 'Roboto-Light' !important;
}

.mat-form-field-infix {
  font-family: 'Roboto-Light' !important;
}

.mat-form-field-label {
  font-size: 15px !important;
  color: map-get($cartus-palette, 'cartus_gray_medium');
  font-family: 'Roboto-Light' !important;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border: 1px solid #b8bec5;
}

.customerPreference-select {
  color: white;
  font-family: 'Roboto', sans-serif !important;
  font-weight: bold;
}

.customerPreference-unselect {
  color: map-get($cartus-palette, 'cartus_black');
  background-color: transparent;
  font-family: 'Roboto-Light' !important;
}

.mobile-field {
  display: flex;
}

.mat-form-field-appearance-outline {
  .mat-form-field-outline-start {
    border-radius: 5px 0 0 5px;
  }
}

.mat-form-field.mat-form-field-invalid {
  .mat-form-field-label {
    color: map-get($cartus-palette, 'cartus_gray_medium');
  }
}

input:-internal-autofill-selected {
  background-color: red !important;
}

.mat-form-field {
  font: 500 14px/1.1 Roboto, sans-serif !important;
  width: 280px;
  @include respond-below(sm) {
    width: 300px;
  }
}
.mat-form-field-appearance-outline._mat-animation-noopable:not(.mat-form-field-disabled)
  .mat-form-field-flex:hover
  ~ .mat-form-field-outline,
.mat-form-field-appearance-outline._mat-animation-noopable
  .mat-form-field-outline,
.mat-form-field-appearance-outline._mat-animation-noopable
  .mat-form-field-outline-start,
.mat-form-field-appearance-outline._mat-animation-noopable
  .mat-form-field-outline-end,
.mat-form-field-appearance-outline._mat-animation-noopable
  .mat-form-field-outline-gap {
  transition: none;
  color: #707070;
}
// mat-input focused color
.mat-form-field-appearance-outline
  .mat-form-field-outline-thick
  .mat-form-field-outline-start,
.mat-form-field-appearance-outline
  .mat-form-field-outline-thick
  .mat-form-field-outline-end,
.mat-form-field-appearance-outline
  .mat-form-field-outline-thick
  .mat-form-field-outline-gap {
  border-width: 1px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 4px 0 0 4px !important;
  border-right-style: none;
}
.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 4px 4px 0 !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-gap {
  border: none;
}
.mat-form-field-appearance-outline {
  &.mat-form-field {
    .mat-input-element {
      font: 300 16px/1.1 Roboto-Light, sans-serif !important;
      margin: 0;
      padding: 3px 0 0 5px;
      @include respond-below(sm) {
        font-size: 14px !important;
        padding: 1px 0 0 5px;
      }
    }
  }
}

.mat-select-value {
  padding-top: 5px;
  padding-left: 5px;
  font-size: 14px;
  font-family: 'Roboto-Light';
}

.mat-error {
  font-size: 14px;
  @include respond-below(md) {
    font-size: 12px;
  }
}

.cdk-overlay-pane {
  max-width: 58vw !important;
}

.dialog-box-text {
  color: map-get($cartus-palette, 'cartus_blue');
}

.dialog-box-text p {
  font-family: 'Roboto-Regular' !important;
}

.mat-icon.mat-warn {
  color: map-get($cartus-palette, 'cartus_black');
}

:-ms-fullscreen,
:root .mat-dialog-container {
  overflow-x: hidden !important;
}
_:-ms-fullscreen,
:root .edit-icon-div {
  margin-top: 1rem !important;
}
_:-ms-fullscreen,
:root .profile-edit-icon {
  margin-top: 0rem !important;
}
.cdk-overlay-container {
  z-index: 9999 !important;
}

.mat-dialog-container {
  border-radius: 8px !important;
}

@media screen and (max-width: 567px) {
  // .mat-snack-bar-container {
  //   left: 0%;
  // }

  .mat-dialog-container {
    display: block;
    padding: 15px;
  }

  .cdk-overlay-pane {
    max-width: 96vw !important;
  }

  .required-fields {
    margin-top: 0px !important;
  }

  .mat-button-field {
    font-size: 18px !important;
  }

  .mat-select-value {
    font-size: 14px;
    padding-top: 0;
  }

  .mat-form-field-label {
    left: -12px !important;
    font-size: 14px !important;
  }

  .agent-form-section {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;

    .input-field {
      width: 322px;
    }

    .address-input-field {
      width: 322px;
    }

    .input-phone-field {
      width: 184px;
    }

    .input-mobile-field {
      width: 100px;
    }

    .state-zip-input-field {
      width: 142px;
      margin-right: 20px;
    }

    .state-zip-input-field:last-child {
      margin-right: 0px;
    }

    .price-input-field {
      width: 118px;
      margin-right: 0px;
    }

    .price-input-field:last-child {
      margin-right: 6px;
      margin-left: 8px;
    }

    .start-end-date-field {
      width: 142px;
      margin-right: 20px;
    }

    .start-end-date-field:last-child {
      margin-right: 0px;
    }

    .mat-button-field {
      width: 98px;
      margin-right: 12px !important;

      .address-input-field {
        width: 305px;
      }
    }

    .input-phone-field {
      width: 184px;
    }

    .input-mobile-field {
      width: 118px;
    }

    .state-zip-input-field {
      width: 150px;
      margin-right: 20px;
    }

    .state-zip-input-field:last-child {
      margin-right: 0px;
    }

    .mat-button-field:last-child {
      margin-right: 0px !important;
    }

    .additional-comments {
      width: 260px;

      .mat-form-field-flex {
        height: 120px !important;
      }
    }

    .property-type-field {
      width: 80%;
      font-size: 15px !important;
    }

    .ownership-type-field {
      width: 80%;
      font-size: 15px !important;
    }

    .input-additional-form {
      margin-bottom: -15px;
    }

    .min-price {
      margin-right: 8px !important;
    }

    .max-price {
      margin-left: 8px !important;
    }

    .textarea-characters {
      top: unset;
      right: unset;
      bottom: -112px;
      float: left;
    }

    .selling-details-address {
      display: block;
    }
  }
}

@media screen and (max-width: 320px) {
  .mat-button-field {
    font-size: 15px !important;
  }

  // .mat-select-value {
  //   font-size: 15px;
  // }

  .mat-form-field-label {
    left: -18px !important;
    font-size: 13px !important;
  }

  .form-container {
    padding: 0;
  }

  .agent-form-section {
    .price-input-field {
      width: 116px;
    }

    .input-phone-field {
      width: 150px;
    }

    .input-mobile-field {
      width: 100px;
    }

    .input-field {
      width: 274px;
    }

    .address-input-field {
      width: 274px;
    }

    .state-zip-input-field {
      width: 125px;
    }

    .mat-button-field {
      width: 82px;
    }

    .property-type-field {
      width: 80%;
      font-size: 15px !important;
    }

    .ownership-type-field {
      width: 80%;
      font-size: 15px !important;
    }

    .additional-comments {
      width: 272px;
    }
  }
}

@media screen and (max-width: 365px) {
  // .mat-form-field-appearance-outline .mat-form-field-flex {
  //   padding: 0 0.75em 0 1.5em !important;
  //   margin-top: -0.25em;
  // }
  .mat-form-field {
    width: 265px;
  }

  .mat-button-field {
    font-size: 15px !important;
  }

  // .mat-select-value {
  //   font-size: 15px;
  // }

  .mat-form-field-label {
    left: -18px !important;
    font-size: 13px !important;
  }

  .form-container {
    padding: 0;
  }

  .agent-form-section {
    .price-input-field {
      width: 114px;
    }

    .input-phone-field {
      width: 150px;
    }

    .input-mobile-field {
      width: 100px;
    }

    .input-field {
      width: 274px;
    }

    .address-input-field {
      width: 274px;
    }

    .state-zip-input-field {
      width: 125px;
    }

    .mat-button-field {
      width: 82px;
    }

    .property-type-field {
      width: 80%;
      font-size: 15px !important;
    }

    .ownership-type-field {
      width: 80%;
      font-size: 15px !important;
    }

    .additional-comments {
      width: 260px;
    }
  }

  .btn-primary {
    width: 97%;
  }
}

// Galaxy Tab
@media screen and (min-width:568px) {
  // .mat-snack-bar-container {
  //   left: 0%;
  // }

  .btn-primary {
    margin-right: 10px;
    margin-left: 10px;
  }

  .btn-primary {
    width: 45%;
  }

  .mat-dialog-container {
    display: block;
    padding: 15px;
  }

  .cdk-overlay-pane {
    max-width: 80vw !important;
  }

  .agent-form-section {
    .address-input-field {
      width: 384px;
    }

    .property-type-field {
      width: 40%;
    }

    .additional-comments {
      width: 394px;
    }

    .ownership-type-field {
      width: 40%;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  // .mat-snack-bar-container {
  //   left: 4%;
  // }

  .btn-primary {
    margin-right: 10px;
    margin-left: 10px;
  }

  .btn-primary {
    width: 97%;
  }

  .mat-dialog-container {
    display: block;
    padding: 15px;
  }

  .cdk-overlay-pane {
    max-width: 80vw !important;
  }

  .agent-form-section {
    .address-input-field {
      width: 387px;
    }

    .state-zip-input-field {
      margin-right: 20px;
      width: 182px;
    }

    .property-type-field {
      width: 38%;
    }

    .ownership-type-field {
      width: 38%;
    }

    .state-zip-input-field:last-child {
      margin-right: 0px;
    }

    .mat-button-field:last-child {
      margin-right: 0px !important;
    }

    .mat-button-field {
      margin-right: 22px !important;
    }

    .input-additional-form {
      margin-bottom: 0px;
    }

    .additional-comments {
      width: 540px;

      .mat-form-field-flex {
        height: 180px !important;
      }

      textarea {
        height: 121px !important;
      }
      textarea.comment-box{
        height: 141px !important;
        overflow-y: auto;
      }
    }
  }
}

@include respond-above(xl) {
  .agent-form-section {
    .address-input-field {
      width: 804px;
    }

    .input-additional-form {
      margin-bottom: 20px;
    }
  }
}

@media screen and (min-width: 1200px) {
  // .mat-snack-bar-container {
  //   left: 25%;
  // }

  .btn-primary {
    margin-right: 20px;
    margin-left: 20px;
  }

  .btn-primary {
    width: 244px;
  }

  .cdk-overlay-pane {
    max-width: 58vw !important;
  }

  .agent-form-section {
    .additional-comments {
      width: 540px;
    }

    .property-type-field {
      width: 21%;
    }

    .ownership-type-field {
      width: 25%;
    }
  }
}

/*******
***Left side navigation menu style
*******/
.left-nav-wrapper {
  color: map-get($cartus-palette, 'cartus_white');
  height: 100vh;
  position: relative;
  .profile {
    padding: 27px 10px 28px 25px;

    // border-bottom: 1px solid #314a85;
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      position: absolute;
      left: 0;
      top: 94px;
      opacity: 0.2;
      background-color: map-get($cartus-palette, 'cartus_white');
    }

    p {
      margin: 0;
    }

    &-title {
      font-size: 14px;

      @include respond-below(md) {
        font-size: 14px;
      }
    }

    &-description {
      font-size: 12px;

      @include respond-below(md) {
        font-size: 14px;
      }
    }

    @include respond-below(md) {
      padding: 27px 10px 28px 30px;
    }
  }
}

.left-nav-wrapper-menus {
  padding: 0;
  list-style: none;
  padding-top: 106px;
  margin: 0;
  li {
    padding: 0;
    margin-bottom: 0;

    a {
      text-decoration: none;
      cursor: pointer;
      font-size: 14px;
      display: flex;
      align-items: center;
      font-weight: 400;
      padding: 13px 10px 13px 22px;
      margin-bottom: 30px;

      .mat-icon {
        font-size: 23px;
        height: auto;
        width: auto;
        margin-right: 7px;
        img {
          width: 28px;

          @include respond-below(md) {
            width: 20px;
          }
        }
      }
      &.tooltip {
        .mat-icon {
          margin-right: 30px;
        }
      }
      &:hover,
      &.active {
        background-color: rgba(255, 255, 255, 0.2);
      }

      &:focus {
        outline: 0;
      }
      @include respond-below(md) {
        margin-bottom: 15px;
      }
    }

    &:focus {
      outline: 0;
    }
  }

  @include respond-below(md) {
    padding: 25px 0;
    margin: 0;

    li {
      a {
        font-size: 18px;
        padding-left: 30px;

        .mat-icon {
          font-size: 20px;
          margin-right: 15px;
        }
      }
    }
  }
}

/***********
** Footer style
************/
.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  // z-index: 1111;
  width: 100%;
  height: 48px;
  transition: all 0.2s ease-in-out;

  .container {
    display: flex;
    padding: 14px 14px 12px 14px;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include respond-below(xl) {
      padding: 18px 32px 9px;
      margin-left: 72px;
    }

    @include respond-below(lg) {
      padding: 18px 32px 9px;
    }

    @include respond-below(md) {
      display: block;
      padding: 12px 10px 8px;
      text-align: center;
      margin-left: 0px;
    }
  }

  .container-rrn {
    display: flex;
    padding: 14px 14px 12px 14px;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include respond-below(lg) {
      padding: 18px 32px 9px;
    }

    @include respond-below(md) {
      display: block;
      padding: 12px 10px 8px;
      text-align: center;
    }
  }

  &-copyright-container {
    line-height: 1.4;

    @include respond-below(lg) {
      font-size: 14px;
      line-height: 1.5;
    }

    @include respond-below(md) {
      font-size: 12px;
    }
  }

  &-menu-container {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;

        a {
          text-decoration: none;
          color: map-get($cartus-palette, 'cartus_footer_link');
          cursor: pointer;

          &:after {
            content: '';
            width: 1px;
            height: 20px;
            display: inline-block;
            background-color: map-get($cartus-palette, 'cartus_gray_medium');
            margin: 0 15px;
            vertical-align: sub;
          }

          &:hover {
            text-decoration: underline;
          }

          @include respond-below(lg) {
            font-size: 14px;

            &:after {
              height: 18px;
              margin: 0 10px;
            }
          }

          @include respond-below(md) {
            font-size: 12px;

            &:after {
              height: 15px;
              margin: 0 10px;
            }
          }
        }

        &:last-child {
          a:after {
            content: none;
          }
        }
      }
    }

    @include respond-below(md) {
      padding-top: 8px;
    }
  }

  @include respond-below(md) {
    height: auto;
  }
}

/****
** Error message overlap fix (create referral page)
****/
.input-field-form.price-min-max-field {
  .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    position: static;
  }
}

/******
** Dialog box style
******/
.message-popup {
  font-size: 26px;
  text-align: center;
}

.dialog-box-actions.row {
  justify-content: space-around;
  margin: 40px 12% 0;

  button {
    margin: 0;
    height: auto;
    width: 150px;

    @include respond-below(md) {
      width: 120px;
    }

    @include respond-below(sm) {
      font-size: 16px;
      width: 100px;
    }

    @include respond-below(xs) {
      font-size: 14px;
    }
  }

  @include respond-below(sm) {
    margin: 30px 0 0;
  }
}

/*****
** Crete referral and Drafts common styles
*****/
.main-container {
  padding: 20px 60px 40px 40px;

  @include respond-below(lg) {
    padding: 20px 20px 40px;
  }

  @include respond-below(md) {
    padding: 0 0 70px;
  }
}

.title-header {
  font-weight: normal;
  color: map-get($cartus-palette, 'cartus_gray_medium');
  margin: 0 0 20px;

  @include respond-below(md) {
    font-size: 18px;
    padding: 10px;
    background-color: map-get($cartus-palette, 'cartus_blue');
    color: map-get($cartus-palette, 'cartus_white');
    margin: 0;
  }
}
.form-container {
  display: block;
  margin-bottom: 10px;
  position: relative;
}
.agent-form-section {
  .form-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    height: calc(100vh - 212px);
    // min-height: 600px;
    // @media screen and (max-width: 1300px) {
    //   min-height: 465px;
    // }
    // @include respond-below(lg) {
    //   min-height: 515px;
    // }
    // @include respond-below(md) {
    //   min-height: auto;
    // }
    @include respond-below(md) {
      height: calc(100vh - 240px);
    }
    @include respond-below(xs) {
      height: calc(100vh - 212px);
    }
  }
}
/******
** Dialog box style
******/
.message-popup {
  font-size: 26px;
  text-align: center;
}
.dialog-box-actions.row {
  justify-content: space-around;
  margin: 40px 12% 0;
  button {
    margin: 0;
    height: auto;
    width: 150px;
    @include respond-below(md) {
      width: 120px;
    }
    @include respond-below(sm) {
      font-size: 16px;
      width: 100px;
    }
    @include respond-below(xs) {
      font-size: 14px;
    }
  }
  @include respond-below(sm) {
    margin: 30px 0 0;
  }
}
/*******
*** Stepper
*******/
.stepper {
  .mat-horizontal-stepper-header-container {
    display: none;
    .mat-step-header {
      &:hover {
        background-color: #fff;
      }
    }
  }
  .mat-stepper-horizontal-line {
    border: 0;
  }
  .mat-horizontal-stepper-header {
    display: none;
    .mat-step-icon {
      font-size: 0;
      width: 0;
      height: 0;
      margin: 0;
    }
    .mat-step-label {
      display: none;
    }
  }
}
.mat-horizontal-content-container {
  @include respond-below(md) {
    padding: 0 10px;
  }
}

.mat-fab.mat-primary.save-btn {
  background-color: #122455;
  width: 60px;
  height: 60px;
  box-shadow: none;
  @include respond-below(md) {
    position: absolute;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    width: 50px;
    height: 50px;
    .mat-button-wrapper {
      padding: 10px 0;
      img {
        width: 20px;
      }
    }
  }
}
//default dropdown arrow position
.mat-select-arrow-wrapper {
  .mat-select-arrow {
    margin: 14px 4px 0;
  }
}
//Input labels position
.mat-form-field-label-wrapper {
  left: 2% !important;
  @include respond-below(sm) {
    left: 6% !important;
  }
  @include respond-below(xs) {
    left: 9% !important;
  }
}
.mat-form-field-outline-start {
  width: 11px !important;
}

/***--------Feedback btn------****/

.feedback-btn{
  position: fixed !important;
  bottom: 0px;
  right: 0;
  background-color: #122455;
   padding: 0px; 
  border: transparent;
  color: white;
  font-size: 20px;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 2%;
  margin-bottom: 1%;
  width: 72px;
  height: 72px;
  @include respond-below(lg) {
    margin-bottom: 1%;
    //margin-right: 4%;
    position: absolute !important;
  }
  @include respond-below(md) {
    margin-right: 4%;
    margin-bottom: 7%;
    width: 40px;
    height: 40px;
    padding:0px;
    position: absolute !important;
  }

  img{
    width: 56px;
    height: 56px;
    @include respond-below(md) {
      width: 26px;
      height: 26px;
    }
  }
  p{
    margin-top: -10px;
    font-size: 12px;
    @include respond-below(md) {
    margin-top: -8px;
    font-size: 7px;
    }
  }
}
@media screen and (max-width: 1480px) and (min-width: 770px) and (max-height: 650px) {

.feedback-btn{ 
  margin-bottom: 2%;
  width: 60px;
  height: 60px;
  img {
  width: 40px;
  height: 40px;
}}
}
/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px;
  }
}
app-create-referral,
app-customer-info,
app-root {
  display: block;
  height: 100%;
}
:-ms-fullscreen,
:root .feedback-module{
margin-top: 6% !important;
}

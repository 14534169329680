//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

/* Description:
------------------------

sm for mobile devices with resolution of 567px. eg: iphone 5
md for mobile devices with resolution of 768px. eg: iphone 6
lg for tablet devices with resolution of 992px. eg: ipad/tablets
xl for desktop devices with resolution of 1200px. 

Examples:
--------------
For media query code: 
@media (max-width: 991px) please use code @include respond-below(lg),
@media (min-width: 991px) please use code @include respond-above(lg) and
@media (min-width: 992px) and (max-width: 1199px) please use code @include respond-between(lg, xl)

*/
// A map of breakpoints.
$breakpoints: (
  xs: 376px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

@mixin media-up($device) {
  @if ($device == 'desktop') {
    @media (min-width: 960px) {
      @content;
    }
  }
  @if ($device == 'desktop-lg') {
    @media (min-width: 1280px) {
      @content;
    }
  }
}

@mixin media-down($device) {
  @if ($device == 'desktop') {
    @media (max-width: 960px) {
      @content;
    }
  }
  @if ($device == 'desktop-lg') {
    @media (max-width: 1280px) {
      @content;
    }
  }
}

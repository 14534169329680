@mixin font($font-family, $url) {
  @font-face {
    font-family: $font-family;
    src: url('#{$url}.ttf');
    // src: url("#{$url}.eot");
    // src: url("#{$url}.woff2") format("woff2"),
    //   url("#{$url}.woff") format("woff"), url("#{$url}.ttf") format("truetype"),
    //   url("#{$url}.svg") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}
$font-Roboto: 'Roboto', sans-serif;
@include font('Roboto-Black', '/../assets/fonts/Roboto-Black');
@include font('Roboto-BlackItalic', '/../assets/fonts/Roboto-BlackItalic');
@include font('Roboto-Bold', '/../assets/fonts/Roboto-Bold');
@include font('Roboto-BoldItalic', '/../assets/fonts/Roboto-BoldItalic');
@include font('Roboto-Italic', '/../assets/fonts/Roboto-Italic');
@include font('Roboto-Light', '/../assets/fonts/Roboto-Light');
@include font('Roboto-LightItalic', '/../assets/fonts/Roboto-LightItalic');
@include font('Roboto-Medium', '/../assets/fonts/Roboto-Medium');
@include font('Roboto-MediumItalic', '/../assets/fonts/Roboto-MediumItalic');
@include font('Roboto-Heavy', '/../assets/fonts/Roboto-Heavy');
@include font('Roboto-Regular', '/../assets/fonts/Roboto-Regular');
@include font('Roboto-Thin', '/../assets/fonts/Roboto-Thin');
@include font('Roboto-ThinItalic', '/../assets/fonts/Roboto-ThinItalic');
